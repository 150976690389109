/* Customize the geocoder search bar */
.ol-geocoder .gcd-txt-control {
    width: 300px ; /* Adjust width */
    height: 40px; /* Adjust height */
    display: flex;
  }
  
  .ol-geocoder .gcd-txt-control input {
    color: #333; /* Adjust text color */
    font-size: 15px; /* Adjust font size */
    padding: 8px; /* Adjust padding */
    border-radius: 4px; /* Optional: add border radius */
    border: 1px solid #ccc; /* Adjust border */
    font-family: "Inter-Medium", Helvetica;
  }
  
  /* Optional: customize the dropdown results */
  .ol-geocoder .gcd-txt-control ul {
    background-color: #fff; /* Adjust background color for dropdown */
    color: black; /* Adjust text color for dropdown */
    border: 1px solid #ccc;
    font-family: "Inter-Medium", Helvetica;
  }
  .ol-geocoder .gcd-txt-glass {
    display: none;
  }
  .ol-geocoder .gcd-txt-result{
    top:3em !important;
    font-family: "Inter-Medium", Helvetica;
  }
