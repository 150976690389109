.summary-heading
{
  border-radius: 10px 10px 0px 0px;
background: rgba(217, 217, 217, 0.30);
color: #000;
padding: 21px;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 125%;

}

.header-content{
  background-color: orange;
  margin-left: 15px;
  margin-top: 10px;
padding-top: 14px;
  height: 48px;
  width: 30px;
}
.output-content {
    display: flex;
    flex-direction: column;
    width: calc((109% - 160px) / 2);
  }
  
.output-summary {
    height: auto;
   
    border: 1px solid #ccc;
    border-radius: 8px;
    margin: 10px;

 }
 .output-summarydashboard {
  height: 100vh;
  width:'100%';
  
  flex: 1;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-left: 10px;

}
  .advisory
  {
    width: 70%;

  }
  .crop-ranking-list {
    width: 100%;
    padding: 20px;

  }
  
  .crop-ranking-container {
    width: 100%;
    margin-top: 10px;
  }
  
  .crop-ranking-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .crop-ranking-table th,
  .crop-ranking-table td {
    padding: 10px;
    text-align: left;
  }
  
  .ranking-number {
    width: 40px;
    height: 40px;
    background: #026B01;
    color: white;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }
  
  .crop-ranking-item:hover {
    background-color: #f0f0f0; /* Change background color on hover */
    cursor: pointer;
  }
  
  .crop-ranking-item.selected {
    background-color: #f0f0f0; /* Change background color for selected item */
  }
  .crop-ranking-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.40); 
  }
  .crop-name,
.crop-score {
  font-weight: bold; /* Make Crop Name and Total Score bold */
  font-size: 16px; /* Adjust font size as needed */
  color: var(--text-primary, rgba(0, 0, 0, 0.87));
  line-height: 150%;
  letter-spacing: 0.15px;
}
  
  .divider {
    border: none;
    width: 100%;
  }
/*  */

.crop-ranking-item:hover {
  background-color: #f0f0f0; /* Change background color on hover */
  cursor: pointer;
}

.crop-ranking-item.selected {
  background-color: #f0f0f0; /* Change background color for selected item */
}
.button-container {
  display: flex;
  gap: 10px; 
  margin: 20px;
}

.styled-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  border: none;
  cursor: pointer;
}

.styled-button-left {
  border-radius: 10px ;
  background-color: #F5821F;
  opacity: 1;
  width: auto;
}

.styled-button-right {
  border-radius: 10px;
  background-color: #00253D;
  opacity: 1;
  width: 266px;
}

.button-icon {
  margin-left: 5px;
}
