/* Full-screen container */
.main-screen {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

  /* World map image styling */
  .world-map {
    position: absolute;
    bottom: 0;
    right:0;
    object-fit: none; /* This will cover the whole screen */
    z-index: -1; /* Place it behind other content */
    height: calc(100vh - 45px);
    width: calc(100vw - 430px);
  }
  
  /* Content styling */
  .content {
    position: relative;
    z-index: 1; /* Place it above the world map */
    /* Add your content styles here */
  }
  .sidepannel {
    position: relative;
    z-index: 6;
    width: 320px; /* Set the width of the LeftPanelExplore */
  }
  
  .left-panel {
    padding: 5%;
    background: white;
    width: 420px;
    height: 100vh;
    flex-shrink: 1;
    box-sizing: border-box;
    transition: width 0.3s, background-color 0.3s;
  }
  
  .left-panel.collapsed {
    width: 50px; 
    background-color: white;
    height: 50px;

  }
 
  .collapse-button {
    position: absolute;
    top: 10px;
    cursor: pointer;
    font-size: 20px;
    color: #000;
    z-index: 1;
    transition: right 0.3s ease; /* Smooth transition */
  }
  
  .collapse-button.collapsed {
    right: -10;
  }
  
  .collapse-button.expanded {
    right: -70px;
  }
  
  
 
  .select-options select {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
  }
/* mainScreen.css */
.custom-button {
    display: inline-flex;
    justify-content: space-around;
    padding: 10px 10px;
    align-items: center;
    border-radius: 4px;
    color: white;
    background: #00253D;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20);
    font-size: 15px;
    font-weight: 500;
    line-height: 2vh;
    letter-spacing: 0.46px;
    text-transform: uppercase;
    margin-right: 10px;
    border: none;
    /* width: 100%; */
    margin-bottom: 2vh;
  }
  .custom-buttontop {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 15px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0.46px;
    border: none;
    cursor: pointer;
    height:53px;
  }

  .custom-buttonnav:hover,
  .custom-buttonnav.active {
    background: #F5821F;
  }
  .custom-buttonnav{
    display: inline-flex;
    align-items: center;
    color: white;
    font-size: 15px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0.46px;
    border: none;
    cursor: pointer;
    height:53px;
  }

  .custom-buttontop:hover,
  .custom-buttontop.active {
    background: #F5821F;
  }

  .output-nav-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    color: white;
    background: #91B187;
    font-size: 15px;
    font-weight: 500;
    line-height: 2vh;
    letter-spacing: 0.46px;
    margin-right: 10px;
    border: none;
    cursor: default;
    width: 700px;
    margin-bottom: 2vh;
  }
  .custom-bar {
    display: inline-flex;
    justify-content:center;
    align-items: center;
    border-radius: 4px;
    color: white;
    background: #00253D;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20);
    font-size: 15px;
    font-weight: 500;
    line-height: 2vh;
    letter-spacing: 0.46px;
    text-transform: uppercase;
    margin-right: 10px;
    border: none;
    cursor:default;
    width: 100%;
    margin-bottom: 2vh;
  }
  .custom-buttontop.clicked {
    background: #F5821F;
    color: white; /* New color for clicked state */
}
  .button-container {
    margin-bottom: 20px;
  }
  .output-portfolio {
    overflow: hidden;

  }
  
  .output-header {
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: space-between; /* Add this to space items apart */
    align-items: center;
    background: #91B187;
  }
  .output-header h1 {
    color: white;
  }
  

  .output-main {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  
  .output-cards {
    display: flex;
    /* justify-content: space-between; */
    /* padding: 7px; */
    height: 126px;
    justify-content: space-between;
  }
  
  .output-card {
    /* flex: 0.5; */
    border: 1px solid rgba(0, 0, 0, 0.40);
    border-radius: 8px;
    padding: 10px;
    /* margin: 10px; */
    width: 22%;
    text-align: center;
    background-color: rgba(217, 217, 217, 0.30);

  }
  .card-title
{
color: #000;
text-align: center;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 150%; 
  }

.summary-heading
{
  border-radius: 10px 10px 0px 0px;
background: rgba(217, 217, 217, 0.30);
color: #000;
padding: 21px;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 125%;
}
.crop-rank {
  /*height: auto;*/
  /* background: rgba(217, 217, 217, 0.30); */
  height: 100%;
  padding-left: 10px;
  scroll-behavior: auto;
}

.dropdown {
  margin-bottom: 4px;
  width: 250px;
  padding: 10;
}
.monitorcontent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.coming-soon {
  color: #91B187;
  font-size: 2em;
  text-align: center;
}

.custom-buttontop2 {
  padding: 10px 10px;
  border-radius: 4px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20);
  margin: 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0.46px;
  text-transform: uppercase;
  border: none;
  height: 30px;
  cursor: pointer;
  background: #91B187;
  border-radius: 5px;
  margin-top: 15px;
  margin-left:4vw;
}

.custom-buttontop2:hover,
.custom-buttontop2.active {
  background: #F5821F;
}

.advisorymain-screen {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: scroll;
}
.advisoryright-panel {
margin-top: 2px;
  border-radius: 15px;
  background: white;
  height: auto;
  width: calc(100vw - 670px); 
  margin-left: 30px; /* Add some space between left and right panels */
  box-sizing: border-box; /* Include padding in width and height */
}
.details-row{
  margin-bottom: 10px;
}
.activities-tables {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  /* gap: 20px; */
  padding: 20px;
}

.activity-table {
 margin-bottom: 50px;
}

.activity-table table {
  width: 100%;
  border-collapse: collapse;
}

.activity-table th, .activity-table td {
  border: 1px solid #fff;
  padding: 8px;
  background-color: #f2f2f2; 
  
}
.activity-table th
{
  width: 250px
}
.activity-table th {
  background-color: #f2f2f2; /* grey background */
  color: #333;
}

.activity-table tr.even-row {
  background-color: #f2f2f2; /* grey background for even rows */
}

.activity-table tr.odd-row {
  background-color: #fff; /* white background for odd rows */
}
