
.summary-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.summary-table th, .summary-table td {
    padding: 8px;
    text-align: center;
}

.summary-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.circleindex {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #026B01;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}
