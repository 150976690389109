/* Global Styles */

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
    background-color: #f9f9f9;
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
    margin-bottom: 0.5em;
  }
  
  h1 {
    font-size: 36px;
  }
  
  h2 {
    font-size: 24px;
  }
  
  h3 {
    font-size: 18px;
  }
  
  p {
    margin-bottom: 1em;
  }
  
  ul, ol {
    margin-bottom: 1em;
    padding-left: 1em;
  }
  
  li {
    margin-bottom: 0.5em;
  }
  
  a {
    text-decoration: none;
    color: #337ab7;
  }
  
  a:hover {
    color: #23527c;
  }
  
  /* Section Styles */
  
  section {
    padding: 2em;
    margin-bottom: 2em;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  section h2 {
    margin-top: 0;
  }
  
  #home {
    background-image: url('https://www.tara4loans.com/assets/img/background.jpg');
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
  
  #home h1 {
    font-size: 48px;
  }
  
  #what-is-tara {
    background-color: #f7f7f7;
  }
  
  #tara-readiness {
    background-color: #f2f2f2;
  }
  
  #data-specifications {
    background-color: #e7e7e7;
  }
  
  #impact {
    background-color: #d7d7d7;
  }
  
  #users {
    background-color: #c7c7c7;
  }
  
  #common-questions {
    background-color: #b7b7b7;
  }
  
  #contact {
    background-color: #a7a7a7;
  }
  
  /* Responsive Styles */
  
  @media (max-width: 768px) {
    section {
      padding: 1em;
      margin-bottom: 1em;
    }
  }
  
  @media (max-width: 480px) {
    section {
      padding: 0.5em;
      margin-bottom: 0.5em;
    }
  }
  .s-repeatable-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .s-repeatable-item {
    flex: 1;
    min-width: 200px; /* Adjust based on your design */
    max-width: 24%; /* Adjust based on your design */
    margin: 0 10px; /* Adjust based on your design */
    box-sizing: border-box;
  }
  
  .s-item-media-wrapper {
    margin-bottom: 10px; /* Adjust based on your design */
  }
  
  .s-item-text-group {
    text-align: center; /* Center align text */
  }
  
  .s-text-color-custom1 {
    color: white;
    font-size: 50px;
    font-weight: bold;
  }
  
  .s-font-heading {
    color: white;
    font-size: 22px;
    font-weight: bold;
  }
  
  .s-font-body {
    color: white;
  }
  .s-img-wrapper {
    font-size: 20px;
    font-weight: bold;
  }
  .s-item-subtitle
  {
    font-size: 20px;
    font-weight: '700';
  }
  .s-item-text
  {
    font-size: 18px;
    font-weight: '700';
  }